// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AsKmPn5RPshTfR0y2Sy7{position:relative;display:flex;flex-grow:1;height:25px}.jRWCvB8Nfmo37RjR8QFQ{position:absolute;right:8px;top:6px;z-index:10}._y2n1HpW4G0cKTIWgzvr{width:100%}._y2n1HpW4G0cKTIWgzvr input{height:25px;padding-right:78px;text-overflow:ellipsis}.URzjSuPv7E8AT6YxKapQ input{height:32px}`, "",{"version":3,"sources":["webpack://./components/IntegrationInputField/IntegrationInputField.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA,CACA,UAAA,CAGF,sBACE,UAAA,CAEA,4BACE,WAAA,CACA,kBAAA,CACA,sBAAA,CAKF,4BACE,WAAA","sourcesContent":[".root {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  height: 25px;\n}\n\n.icons {\n  position: absolute;\n  right: 8px;\n  top: 6px;\n  z-index: 10;\n}\n\n.input-container {\n  width: 100%;\n\n  input {\n    height: 25px;\n    padding-right: 78px;\n    text-overflow: ellipsis;\n  }\n}\n\n.button-input-height {\n  input {\n    height: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AsKmPn5RPshTfR0y2Sy7`,
	"icons": `jRWCvB8Nfmo37RjR8QFQ`,
	"input-container": `_y2n1HpW4G0cKTIWgzvr`,
	"button-input-height": `URzjSuPv7E8AT6YxKapQ`
};
export default ___CSS_LOADER_EXPORT___;
